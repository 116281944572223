import React, { useRef, useState } from "react";
import "./landing.scss";
import { FaRegCirclePlay } from "react-icons/fa6";
import { GoUnmute, GoMute } from "react-icons/go";

const Landing = () => {
  const [isClicked, setIsClicked] = useState(true);
  const [isMute, setIsMute] = useState(true);
  const videoRef = useRef(null);

  // Function to play the video
  const playVideo = (e) => {
    e.stopPropagation();
    setIsClicked(true);
    // Check if the video ref is assigned
    if (videoRef.current) {
      // Play the video
      videoRef.current.play();
    }
  };

  const onClickVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }

    setIsClicked(false);
  };

  return (
    <div className="video-container">
      <video ref={videoRef} autoPlay muted={isMute} loop id="background-video">
        <source src="/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-content" onClick={onClickVideo}>
        <button
          id="mute"
          onClick={(e) => {
            e.stopPropagation();
            setIsMute(!isMute);
          }}
        >
          {isMute ? (
            <GoMute size="40" color="white" />
          ) : (
            <GoUnmute size="40" color="white" />
          )}
        </button>
        <button
          id="elementId"
          onClick={playVideo}
          style={{ display: isClicked ? "none" : "flex" }}
        >
          <FaRegCirclePlay size="70" color="white" />
        </button>
      </div>
    </div>
  );
};

export default Landing;
