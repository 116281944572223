import React from "react";
import "./footer.scss";
import { BsFacebook } from "react-icons/bs";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { footerData } from "../../data";
import { useLanguage } from "../../contexts/LanguageContext";

const Footer = () => {
  const { lang } = useLanguage();
  const data = footerData[lang];

  return (
    <div className="footer">
      <div className="container">
        <div className="about-footer">
          <div className="logo">{data.title}</div>
          <div className="text">{data.text}</div>
        </div>
        <div className="column">
          <h3>{data.linkTitle}</h3>
          <div>
            {data.links.map((link) => (
              <a key={link.id} href={link.to} target="_blank" rel="noreferrer">
                {link.title}
              </a>
            ))}
          </div>
          <div></div>
        </div>
      </div>
      <div className="copyright">
        <div className="left">
          <span>
            Copyright <AiOutlineCopyrightCircle style={{ margin: "0 5px" }} />
          </span>
          <strong>
            <a href="https://risczp.com/" target={"_blank"} rel="noreferrer">
              RISCZP
            </a>
          </strong>
        </div>
        <div className="icons">
          {/* <div className="icon">
            <a href="tel:080021000" target={"_blank"} rel="noreferrer">
              0800 21 000
            </a>
          </div> */}
          <div className="icon">
            <a
              href="mailto:info@turistickaorganizacijastrpce.com"
              target={"_blank"}
              rel="noreferrer"
            >
              info@turistickaorganizacijastrpce.com
            </a>
          </div>
          {/* <div className="icon">
            <a
              href="https://www.facebook.com/%C5%BDenski-Inkluzivni-Centar-%C5%BDEC-QPG-WIC-100328075657801"
              target={"_blank"}
              rel="noreferrer"
            >
              <BsFacebook size={15} />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
