import React from "react";
import Layout from "../../../components/admin/Layout/Layout";
import Dashboard from "../Dashboard/Dashboard";
import "./admin.scss";

const Admin = () => {
  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
};

export default Admin;
