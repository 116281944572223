import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Fallback = () => {
	const navigate = useNavigate();

	console.log('da')
	useEffect(() => {
		navigate('/')
	}, [])

		return null
}

export default Fallback