import { useCallback } from "react";

const { useContext } = require("react");
const { useState } = require("react");
const { createContext } = require("react");

const LanguageContext = createContext(null);

const getLanguage = () => {
  const lang = localStorage.getItem("lang")
    ? JSON.parse(localStorage.getItem("lang"))
    : "sr";

  return lang;
};

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState(getLanguage());

  const changeLanguage = useCallback((lng) => {
    setLang(lng);
    localStorage.setItem("lang", JSON.stringify(lng));
  }, []);

  return (
    <LanguageContext.Provider value={{ lang, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error("Wrapp App component with LanguageProvider!");
  }

  return context;
};
