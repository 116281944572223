import React from "react";
import { useParams } from "react-router-dom";
import "./blogDetails.scss";
import Annoucment from "../../../components/Announcment/Announcment";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { useBlogs } from "../../../contexts/BlogContext";

const BlogDetails = () => {
  const { id } = useParams();
  const { blogs } = useBlogs();
  let blog = blogs.find((blog) => blog._id === parseInt(id));
  // const formatDate = Moment(blog?.createdAt).format("DD-MM-YYYY");
  let images = blog?.files.split("#");

  return (
    <>
      <Annoucment />
      <Navbar />
      <div className="blog">
        <div className="container">
          {blog && (
            <div className="content">
              <div className="img mb-5">
                <img src={`/blogs/${images[0] || null}`} alt="" />
                {/* <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    color: "#666",
                  }}
                >
                  {formatDate}
                </div> */}
              </div>
              {blog && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: blog?.draft,
                  }}
                  style={{ maxWidth: "100%", textAlign: " justify" }}
                ></div>
              )}
              <div className="files">
                <img src={`/blogs/${images[1] || null}`} alt="" />
                <img src={`/blogs/${images[2] || null}`} alt="" />
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetails;
