import React from 'react'
import './hero.scss'

const Hero = ({ text }) => {
  return (
    <div className='hero'>
        <div className="hero-content">
						 <h3>{text}</h3>
        </div>
    </div>
  )
}

export default Hero