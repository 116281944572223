import React from "react";
import { tourismData } from "../../../data";
import UserLayout from "../Layout/Layout";
import "./tourism.scss";
import { useLanguage } from "../../../contexts/LanguageContext";

const Tourism = () => {
  const { lang } = useLanguage();
  const data = tourismData[lang];

  return (
    <UserLayout text={data.title}>
      <div className="aboutUs">
        <div className="container">
          <div className="content">
            <p>
              {data.text}
            </p>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default Tourism;
