import React from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import { undpData } from "../../data";
import "./undp.scss";

const Undp = () => {
  const { lang } = useLanguage();
  const data = undpData[lang];

  return (
    <div className="about">
      <div className="container">
        <div className="undp-content">
          <div className="logo-div">
            <img src="./undp-logo.svg" alt="" />
          </div>
          <p>{data.text}</p>
        </div>
      </div>
    </div>
  );
};

export default Undp;
