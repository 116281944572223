import React from "react";
import { Outlet } from "react-router-dom";
import Annoucment from "../../components/Announcment/Announcment";
import Navbar from "../../components/Navbar/Navbar";
import Landing from "../../components/Landing/Landing";
import About from "../../components/About/About";
import Footer from "../../components/Footer/Footer";
import TouristOffers from "../../components/TouristOffers/TouristOffers";
import Blog from "../../components/Blog/Blog";
import Undp from "../../components/UNDP/Undp";

const Home = () => {
  return (
    <div>
      <Annoucment />
      <Navbar />
      <Landing />
      <TouristOffers />
      <About />
      <Blog />
      <Undp />
      <Footer />
      <Outlet />
    </div>
  );
};

export default Home;
