export const config = {
  en: {
    home: {
      title: "Welcome",
    },
  },
  sr: {
    home: {
      title: "Dobrodosli",
    },
  },
  al: {
    home: {},
  },
};

export const navData = {
  en: {
    links: [
      { id: 1, title: "Home", icon: "icon", to: "/" },
      { id: 2, title: "Municipality of Strpce", icon: "icon", to: "/city" },
      { id: 3, title: "Tourism offer", icon: "icon", to: "/offers" },
      { id: 4, title: "Historical legacy", icon: "icon", to: "/history" },
      { id: 5, title: "Tradition", icon: "icon", to: "/tradition" },
      { id: 6, title: "Countryside Tourism", icon: "icon", to: "/tourism" },
      { id: 7, title: "Events", icon: "icon", to: "/events" },
      { id: 8, title: "About Us", icon: "icon", to: "/about" },
      { id: 9, title: "Contact", icon: "icon", to: "/contact" },
    ],
  },
  sr: {
    links: [
      { id: 1, title: "Početna", icon: "icon", to: "/" },
      { id: 2, title: "O Štrpcu", icon: "icon", to: "/city" },
      { id: 3, title: "Tursitička ponuda", icon: "icon", to: "/offers" },
      { id: 4, title: "Istorijsko nasledje", icon: "icon", to: "/history" },
      { id: 5, title: "Tradicija", icon: "icon", to: "/tradition" },
      { id: 6, title: "Seoski turizam", icon: "icon", to: "/tourism" },
      { id: 7, title: "Dogadjaji", icon: "icon", to: "/events" },
      { id: 8, title: "O nama", icon: "icon", to: "/about" },
      { id: 9, title: "Kontakt", icon: "icon", to: "/contact" },
    ],
  },
  al: {
    links: [
      { id: 1, title: "Hyrja", icon: "icon", to: "/" },
      { id: 2, title: "Shterpce", icon: "icon", to: "/city" },
      { id: 3, title: "Oferta turistike", icon: "icon", to: "/offers" },
      { id: 4, title: "Historike", icon: "icon", to: "/history" },
      { id: 5, title: "Tradita", icon: "icon", to: "/tradition" },
      { id: 6, title: "Turizmi Ne Fasht", icon: "icon", to: "/tourism" },
      { id: 7, title: "Ngjarjet", icon: "icon", to: "/events" },
      { id: 8, title: "Rreth Nesh", icon: "icon", to: "/about" },
      { id: 9, title: "Kontakoni", icon: "icon", to: "/contact" },
    ],
  },
};

export const homeData = {
  en: {
    title: "Our Mission",
    text: "The mission of the Women's Inclusive Center is to contribute to the suppression and prevention of domestic violence by providing accessible, effective, comprehensive and sustainable assistance and protection to victims of domestic violence.",
    btn: "Read more",
  },
  sr: {
    title: 'Turistička Organizacija "Štrpce"',
    text: 'Turistička Organizacija "Štrpce" je osnovana u malom, ali šarmantnom gradiću Strpcu, smještenom duboko u pitoresknom planinskom kraju. Ova organizacija nije samo promovirala prirodnu ljepotu regije već je također bila važan faktor u podizanju svijesti o kulturnom i istorijskom nasljeđu mjesta. Osnovana je u 1970-im godinama s ciljem da podstakne razvoj turizma u Strpcu, organizirajući lokalne manifestacije, promovirajući planinarske staze i upoznavajući posjetitelje sa bogatom kulturnom baštinom područja.',
    btn: "Opširnije",
  },
  al: {
    title: "MISIONI YNE",
    text: `Misioni i Qendra e përfshirjes së grave është të kontribuojë në shtypjen dhe parandalimin e dhunës në familje duke ofruar ndihmë dhe mbrojtje të aksesueshme, efektive, gjithëpërfshirëse dhe tëqëndrueshme për viktimat e dhunës në familje.`,
    btn: "Lexo më shumë",
  },
};

export const newsData = {
  en: {
    title: "Events",
    text: "Stay up to date with the latest news.",
    button: "All news",
  },
  sr: {
    title: "Dogadjaji",
    text: "Budite u toku sa najnovijim dogadjajima.",
    button: "Sve vesti",
  },
  al: {
    title: "Lajme",
    text: "Qëndroni të përditësuar me lajmet më të fundit.",
    button: "Të gjitha lajmet",
  },
};

export const missionData = {
  en: {
    title: "Our Mission",
    text: `The Tourist Organization of Štrpce (TOŠ) was founded in 2024 in order to promote and support the development of tourism potential in the municipality of Štrpce.  The young people with a vision who make up the TOS team will do everything to ensure that tourists and all interested parties receive all the necessary information about the entire tourist offer in the municipality of Štrpce, as well as to make Štrpce an attractive destination for all those who have not visited this region or who have  decided to visit it again... We owe the establishment of TOŠ to the Municipality of Štrpce, UNDP and the EU office in Kosovo`,
  },
  sr: {
    title: "O nama",
    text: `Turistička organizacija Štrpca (TOŠ) je osnovana 2024 godine u cilju promocije i podrške razvoju turističkih potencijala u opštini Štrpce. Mladi ljudi sa vizijom koji čine tim TOŠ-a učiniće sve da turisti i svi zainteresovani dobiju sve potrebne informacije o celokupnoj turističkoj ponudi u opštini Štrpce kao i da uz saradnju sa lokalnom samoupravom učine Štrpce primamljivom destinacijom za sve one koji nisu posetili ovaj kraj ili su odlučili da ga ponovo obidju.. Zahvalnost osnivanju TOŠ-a dugujemo Opštini Štrpce, UNDP-u i kancelariji EU na Kosovu.`,
  },
  al: {
    title: "MISIONI YNE",
    text1: `Organizata Turistike e Shtërpcës (TOŠ) është themeluar në vitin 2024 me qëllim të promovimit dhe mbështetjes së zhvillimit të potencialit turistik në komunën e Shtërpcës.  Të rinjtë me vizion që përbëjnë ekipin e TOS do të bëjnë gjithçka që turistët dhe të gjithë të interesuarit të marrin të gjitha informacionet e nevojshme për të gjithë ofertën turistike në komunën e Shtërpcës, si dhe që Shtërpca të bëhet një destinacion tërheqës për të gjithë. Ata që nuk e kanë vizituar këtë rajon apo që kanë vendosur ta vizitojnë sërish... Themelimin e TOŠ ia kemi borxh Komunës së Shtërpcës, UNDP-së dhe zyrës së BE-së në Kosovë.`,
  },
};

export const footerData = {
  en: {
    title: "The Local Tourist Organization of Štrpce",
    text: "The Local Tourist Organization of Štrpce (LTOŠ) was founded in 2024 in order to promote and support the development of tourism potential in the municipality of Štrpce.",
    linkTitle: "Useful Links",
    links: [
      { id: 1, to: "https://www.undp.org/kosovo", title: "UNDP" },
      {
        id: 2,
        to: "https://www.facebook.com/strpceopstina/?locale=sr_RS",
        title: "Municipality of Strpce",
      },
      { id: 3, to: "https://risczp.com/", title: "RISC Zubin Potok" },
    ],
    copyright: "All rights reserved",
  },
  sr: {
    title: "Lokalna Turisticka Organizacija Strpce",
    linkTitle: "Korisni linkovi",
    text: "Lokalna Turistička organizacija Štrpca (LTOŠ) je osnovana 2024 godine u cilju promocije i podrške razvoju turističkih potencijala u opštini Štrpce.",
    links: [
      { id: 1, to: "https://www.undp.org/kosovo", title: "UNDP" },
      {
        id: 2,
        to: "https://www.facebook.com/strpceopstina/?locale=sr_RS",
        title: "Opština Štrpce",
      },
      { id: 3, to: "https://risczp.com/", title: "RISC Zubin Potok" },
    ],
    copyright: "Sva prava zadržana",
  },
  al: {
    title: "Organizata Lokale Turistike Shtërpcë",
    text: `Organizata Lokale Turistike e Shtërpcës (LTOŠ) është themeluar në vitin 2024 me qëllim të promovimit dhe mbështetjes së zhvillimit të potencialit turistik në komunën e Shtërpcës.`,
    linkTitle: "Lidhje të dobishme",
    links: [
      { id: 1, to: "https://www.undp.org/kosovo", title: "UNDP" },
      {
        id: 2,
        to: "https://www.facebook.com/strpceopstina/?locale=sr_RS",
        title: "Komuna e Shtërpcës",
      },
      { id: 3, to: "https://risczp.com/", title: "RISC Zubin Potok" },
    ],
    copyright: "Të gjitha të drejtat e rezervuara",
  },
};

export const aboutUsData = {
  en: {
    title: "ABOUT US",
    text: "The Tourist Organization of Štrpce (TOŠ) was founded in 2024 in order to promote and support the development of tourism potential in the municipality of Štrpce.  The young people with a vision who make up the TOS team will do everything to ensure that tourists and all interested parties receive all the necessary information about the entire tourist offer in the municipality of Štrpce, as well as to make Štrpce an attractive destination for all those who have not visited this region or who have  decided to visit it again... We owe the establishment of TOŠ to the Municipality of Štrpce, UNDP and the EU office in Kosovo.",
  },
  sr: {
    title: "O NAMA",
    text: "Turistička organizacija Štrpca ( TOŠ) je osnovana 2024 godine u cilju promocije i podrške razvoju turističkih potencijala u opštini Štrpce. Mladi ljudi sa vizijom koji čine tim TOŠ-a učiniće sve da turisti i svi zainteresovani dobiju sve potrebne informacije o celokupnoj turističkoj ponudi u opštini Štrpce kao i da uz saradnju sa lokalnom samoupravom učine Štrpce primamljivom destinacijom za sve one koji nisu posetili ovaj kraj ili su odlučili da ga ponovo obidju.. Zahvalnost osnivanju TOŠ-a dugujemo Opštini Štrpce, UNDP-u i kancelariji EU na Kosovu.",
  },
  al: {
    title: "PËR NE",
    text: "Organizata Turistike e Shtërpcës (TOŠ) është themeluar në vitin 2024 me qëllim të promovimit dhe mbështetjes së zhvillimit të potencialit turistik në komunën e Shtërpcës.  Të rinjtë me vizion që përbëjnë ekipin e TOS do të bëjnë gjithçka që turistët dhe të gjithë të interesuarit të marrin të gjitha informacionet e nevojshme për të gjithë ofertën turistike në komunën e Shtërpcës, si dhe që Shtërpca të bëhet një destinacion tërheqës për të gjithë. Ata që nuk e kanë vizituar këtë rajon apo që kanë vendosur ta vizitojnë sërish... Themelimin e TOŠ ia kemi borxh Komunës së Shtërpcës, UNDP-së dhe zyrës së BE-së në Kosovë.",
  },
};

export const contactData = {
  en: {
    title: "Contact",
    text: "Contact us via email or phone number.",
  },
  sr: {
    title: "Kontakt",
    text: "Kontaktirajte nas putem elektronske poste ili mobilnog telefona.",
  },
  al: {
    title: "Kontaktoni",
    text: "Na kontaktoni përmes e-mail ose telefonit celular.",
  },
};

export const cityData = {
  en: {
    title: "PLACE OF ŠTRPCA AND HOW TO GET TO IT",
    text: `The municipality of Štrpce is located in the extreme south of Kosovo, on the border with North Macedonia.  The area of ​​the municipality is about 248 km² and includes the territory of sixteen villages of the Sirinić parish.  The seat of the municipality is the place of the same name Štrpce.  The municipality of Štrpce existed until 1965, when it was abolished, and was re-formed in 1988 by separating the settlement from the then municipality of Uroševac.  According to the latest data used by the OSCE mission in Kosovo, about 14,000 inhabitants live in the Štrpce municipality, of which 9,500 belong to the Serbian community, 4,500 belong to the Albanian community, and several dozen belong to the Roma community.
    In the west of the municipality, the river Lepenac springs.  The famous Brezovica ski center is located on Šar-planina, which attracts tens of thousands of guests every year, and there is also one of the national parks, Šar-planina national park.
    Štrpce is the administrative center of the municipality, located in the central part of the municipal territory.  The villages are equally located on both banks of Lepenc.  Seven are located on the left and nine on the right bank of Lepenc.  The municipality of Štrpce borders on the north with the municipalities of Uroševac and Suva Reka, on the east with Prizren, on the west with Kačanik, and on the south with North Macedonia.
    On the southern side, the municipality extends across the Šar ridge with the peaks of Ljuboten (2,498 m), Livadice (2,494 m), Jezerska Čuka (2,604 m) and Bistra (2,651 m).  The western border descends from the Bistre Pass to the Prevalac Pass, which separates Sirinić County from Sredačka County.  The northwestern side of Sirinić stretches from Ošljak (2,207 m) across Kodža Balkana (2,035 m) and Žara mountain (1,694 m), which separate it from the Prizren Podgorje.  The northern border of the parish consists of parts of Jezerska Planina, Studenica and Kurkulica, from where the border in the northeast descends across Graber towards Brodska Klisura on the eastern side.
    Roads connect the Municipality of Štrpce with Prizren and Uroševac, while roads mostly follow the Lepenac River.
   `,
  },
  sr: {
    title: "MESTO ŠTRPCE I KAKO DO NJEGA",
    text: `Opština Štrpce se nalazi se na krajnjem jugu Kosova, na granici sa Severnom Makedonijom. Površina opštine je oko 248 km² i obuhvata teritoriju od šesnaest sela Sirinićke župe. Sedište opštine je istoimeno mesto Štrpce. Opština Štrpce je postojala do 1965. godine kada je ukinuta, a ponovo je formirana 1988. godine izdvajanjem naselja iz tadašnje opštine Uroševac. Po poslednjim podacima kojima se služi misija OEBS-a na Kosovu u opštini Štrpce živi oko 14,000 stanovnika od čega 9.500 pripadnika srpske, 4.500 albanske i nekoliko desetina pripadnika romske zajednice. 
    Na zapadu opštine izvire reka Lepenac. Na Šar-planini se nalazi poznati ski centar Brezovica koji svake godine privuče na desetine hiljada gostiju, a tu je i jedan od nacionalnih parkova, nacionalni park Šar-planina.
    Štrpce je administrativno središte opštine, nalazi se u centralnom delu opštinske teritorije. Sela su podjednako smeštena na obe obale Lepenca. Sedam je smešteno na levoj a devet na desnoj obali Lepenca. Opština Štrpce se graniči sa severa sa opštinama Uroševac i Suva Reka, istoka Prizrenom, zapada Kačanikom, a na jugu sa Severnom Makedonijom.
    Na južnoj strani opština se proteže preko šarskog grebena sa vrhovima Ljuboten (2.498 m), Livadice (2.494 m), Jezerska Čuka (2.604 m) i Bistra (2.651 m). Zapadna granica se od prevoja Bistre spušta na prevoj Prevalac koji Sirinićku župu razdvaja od Sredačke Župe. Severozapadna strana Sirinića se od Ošljaka (2.207 m) proteže preko Kodža Balkana (2.035 m) i planine Žara (1.694 m) koje ga odvajaju od Prizrenskog Podgorja. Severnu granicu župe čine delovi Jezerske planine, Studenica i Kurkulica, odakle se granica na severoistoku spušta preko Grabera prema Brodskoj klisuri na istočnoj strani.
    Putni pravci Opštinu Štrpce povezuju sa Prizrenom i Uroševcem, dok saobraćajnice uglavnom prate reku Lepenac. 
    `,
  },
  al: {
    title: "VENDI SHTERPCE DHE SI TE VIHET DERI TEK AI",
    text: `Komuna e Shtërpcës ndodhet në skajin jugor të Kosovës, në kufi me Maqedoninë e Veriut.  Sipërfaqja e komunës është rreth 248 km² dhe përfshin territorin e gjashtëmbëdhjetë fshatrave të famullisë Siriniq.  Selia e komunës është vendi me të njëjtin emër të Shtërpcës.  Komuna e Shtërpcës ka ekzistuar deri në vitin 1965, kur u shfuqizua, dhe u riformua në vitin 1988 duke e ndarë vendbanimin nga komuna e atëhershme e Uroshevcit.  Sipas të dhënave të fundit të përdorura nga misioni i OSBE-së në Kosovë, në komunën e Shtërpcës jetojnë rreth 14.000 banorë, prej të cilëve 9.500 i përkasin komunitetit serb, 4.500 i përkasin komunitetit shqiptar dhe disa dhjetëra i përkasin komunitetit rom.
    Në perëndim të komunës buron lumi Lepenac.  Qendra e famshme e skijimit të Brezovicës ndodhet në Shar-Planinë, e cila tërheq dhjetëra mijëra mysafirë çdo vit, si dhe është një nga parqet kombëtare, parku kombëtar i Shar-planinës.
    Shtërpca është qendër administrative e komunës, e vendosur në pjesën qendrore të territorit komunal.  Fshatrat ndodhen në mënyrë të barabartë në të dy brigjet e Lepencit.  Shtatë ndodhen në të majtë dhe nëntë në bregun e djathtë të Lepencit.  Komuna e Shtërpcës kufizohet me komunat e Uroshevcit dhe Suharekës në veri, Prizrenit në lindje, Kaçanikut në perëndim dhe Maqedonisë së Veriut në jug.
    Në anën jugore, komuna shtrihet përgjatë kreshtës së Sharrit me majat e Lubotenit (2,498 m), Livadice (2,494 m), Jezerska Čuka (2,604 m) dhe Bistra (2,651 m).  Kufiri perëndimor zbret nga Qafa e Bistres në Qafën e Prevalacit, e cila ndan Qarkun e Siriniqit nga Qarku i Sredaçkës.  Ana veriperëndimore e Sirinić-it shtrihet nga Ošljak (2,207 m) përgjatë Kodža Balkana (2,035 m) dhe malit Žara (1,694 m), që e ndajnë atë nga Podgorja e Prizrenit.  Kufiri verior i famullisë përbëhet nga pjesët e Jezerska Planinës, Studenicës dhe Kurkulicës, nga ku kufiri në verilindje zbret përtej Graberit në drejtim të Brodska Klisura në anën lindore.
    Rrugët e lidhin Komunën e Shtërpcës me Prizrenin dhe Uroshevacin, ndërsa rrugët kryesisht ndjekin lumin Lepenac.
  `,
  },
};

export const historyData = {
  en: {
    title: "CULTURAL, RELIGIOUS AND HISTORICAL HERITAGE",
    text: `The municipality of Štrpce has a large number of cultural monuments.  At the top of Čajlija, above the confluence of the Piljevac stream and Lepenac, you can see a town with two fortification belts that are completely in ruins, but you can see the remains of a donjon tower, as well as the outlines of other buildings.
    In the village of Gornja Bitinja, northwest of the cemetery church of St.  Đorđe, there is a stone slab with an ornament from the 14th century, vertically driven into the ground
    In Štrpac there is a church of St. Nicholas, built in 1576/1577.  year, which are under protection as a cultural monument of exceptional importance.
    In Donja Bitinja there is a modest building with a single nave, semi-arched nave and with a three-sided apse on the outside, dedicated to Saint Theodore Tiron.  This cemetery church of St.  Teodora Tirona from the 16th century was built of crushed stone with a lot of mortar, buried, and the floor was covered with large stone slabs of irregular shape.
    There are two churches in Gotovuša that are cultural monuments.  The first church is dedicated to the Holy Savior, and is also known as the church of St. Demetrius.  According to the founder's fresco inscription, which is partially preserved on the western wall of the nave, it shows that the church was originally dedicated to Saint Nicholas.
    Church of St.  The Church of the Virgin Mary in Gotovuša was built after the restoration of the Patriarchate of Peć in 1557.  The church got its present shape during the renovation in 1886.
    On the Štrpce-Brezovica road, in a meadow 800 meters from the settlement of Brezovica, there is a monument to 46 people who were shot by the Bulgarians on June 28, 1944 in retaliation for one of their soldiers being killed.  Among those killed were twelve children.  In the period after 1999, the monument was the target of attacks several times, but each time it was restored to stand and remind of the terrible crime against Serbs in the Second World War.
   `,
  },
  sr: {
    title: "KULTURNO, VERSKO I ISTORIJSKO NASLEDJE",
    text: `Opština Štrpce poseduje veliki broj spomenika kulture. Na vrhu Čajlije, iznad ušća Piljevačkog potoka u Lepenac, uočava se grad sa dva pojasa utvrđenja koji su potpuno u ruševinama, ali se uočavaju ostaci donžon kule, kao i obrisi drugih građevina. 
    U selu Gornja Bitinja severozapadno od grobljanske crkve Sv. Đorđa nalazi se kamena ploča sa ornamentom iz 14. veka vertikalno pobodena u zemlju
    U Štrpcu se nalazi crkva svetog Nikole, podignuta 1576/1577. godine, koja se nalaze pod zaštitom kao spomenik kulture od izuzetnog značaja. 
    U Donjoj Bitinji nalazi se skromna građevina jednobrodnog, poluobličasto zasvedenog naosa i sa spolja trostranom apsidom posvećena Svetom Teodoru Tironu. Ova Grobljanska crkva Sv. Teodora Tirona iz XVI veka zidana je lomljenim kamenom s dosta maltera, ukopana, a pod je pokriven velikim kamenim pločama nepravilnog oblika.
    U Gotovuši postoje dve crkve koje su spomenici kulture. Prva je crkva posvećena posvećena Svetom Spasu, a poznata je i kao crkva Svetog Dimitrija. Po ktitorskom fresko natpisu koji je delimično sačuvan na zapadnom zidu naosa pokazuje da je crkva prvobitno bila posvećena Svetom Nikoli. 
    Crkva Sv. Bogorodice u Gotovuši sagrađena je posle obnove Pećke patrijaršije 1557. god. Današnji oblik crkva je dobila u obnovi iz 1886. god. 
    Na putu Štrpce—Brezovica, na livadi 800 metara od naselja Brezovica nalazi se spomenik za 46 ljudi koji su Bugari streljali 28. juna 1944. kao odmazdu za jednog ubijenog svog vojnika. Među ubijenima je bilo i dvanaestoro dece. U periodu posle 1999. godine spomenik je bio meta napada više puta, ali je svaki put obnovljen da stoji i podseća na strašan zločin protiv Srba u Drugom svetskom ratu.
    `,
  },
  al: {
    title: "TRASHËGIMIA KULTURORE, FETARE DHE HISTORIKE",
    text: `Komuna e Shtërpcës ka një numër të madh të monumenteve të kulturës.  Në majë të Çajlisë, mbi bashkimin e përroit të Piljevacit dhe Lepencit, mund të shihni një qytet me dy breza fortifikues që janë tërësisht të rrënuara, por mund të shihni mbetjet e një kulle donjon, si dhe konturet e ndërtesave të tjera.  .
    Në fshatin Gornja Bitinja, në veriperëndim të kishës së varrezave të Shën Gjorgjesë, gjendet një pllakë guri me një stoli të shekullit të 14-të, e futur vertikalisht në tokë.
    Në Shtërpac ndodhet kisha e Shën Nikollës, e ndërtuar në vitin 1576/1577.  vit, të cilat janë nën mbrojtje si monument kulture me rëndësi të jashtëzakonshme.
    Në Bitinjën e Madhe ndodhet një ndërtesë modeste me një nef, gjysëm hark dhe me absidë trefaqëshe nga jashtë, kushtuar Shën Theodhor Tironit.  Kjo kishë e varrezave të Shën Teodora Tironës e shekullit të 16-të ishte ndërtuar me gurë të grimcuar me shumë llaç, të groposur dhe dyshemeja ishte e mbuluar me pllaka të mëdha guri në formë të çrregullt.
    Në Gotovushë janë dy kisha që janë monumente kulture.  Kisha e parë i kushtohet Shpëtimtarit të Shenjtë dhe njihet edhe si kisha e Shën Dhimitrit.  Sipas mbishkrimit të afreskut themelues, i cili ruhet pjesërisht në murin perëndimor të naosit, tregon se kisha në fillim i ishte kushtuar Shën Nikollës.
    Kisha e Shën Mërisë Kisha e Virgjëreshës në Gotovushë është ndërtuar pas restaurimit të Patriarkanës së Pejës në vitin 1557. Kisha mori formën e saj aktuale gjatë rinovimit në 1886.
    Në rrugën Shtërpcë-Brezovicë, në një livadh 800 metra larg vendbanimit Brezovicë, gjendet përmendorja e 46 personave që u pushkatuan nga bullgarët më 28 qershor 1944 si hakmarrje për vrasjen e një ushtari të tyre.  Mes të vrarëve ishin dymbëdhjetë fëmijë.  Në periudhën pas vitit 1999, monumenti disa herë ka qenë cak i sulmeve, por çdo herë është restauruar për të qëndruar dhe për të kujtuar krimin e tmerrshëm ndaj serbëve në Luftën e Dytë Botërore.
  `,
  },
};

export const traditionData = {
  en: {
    title: "TRADITION",
    text: `Preservation of tradition is reflected in the cultivation of old crafts, traditional cuisine, folk customs, written word, song, dance and music.  Products made using traditional techniques can be purchased at numerous fairs that are organized throughout the year at various locations in and outside the municipality of Štrpce.
    In restaurants with national cuisine, you will often be served traditional Sharjah cheese, homemade fillet or Sharjah lamb.  Homemade raspberry, blackberry, and blueberry juices are also on offer, and they will welcome you with sweet wild berries.  Dishes are prepared according to tried and tested traditional recipes.
    Through their work, three cultural and artistic societies preserve songs and dances from Sirinić parish and other regions.  With frequent concerts in Štrpce, but also by participating in events in the region, they represent the municipality of Štrpce in the best way, and thus they are true ambassadors of our municipality.
    The White Carnival event (Vučari, the wedding of Marko Kraljević and the burning of the godmother), which is celebrated the day before the beginning of the Easter fast, as well as the Lazarice, which is celebrated a week before Easter on Lazarus Saturday, a holiday celebrating Christ's resurrection of Lazarus after a four-day death, are on the list of cultural  heritage.  Both events bring together a large number of young people and children and thus work to preserve traditions going back decades and centuries.
   `,
  },
  sr: {
    title: "TRADICIJA",
    text: `Očuvanje tradicije se ogleda kroz negovanje starih zanata, tradicionalne kuhinje, narodnih običaja, pisane reči, pesme, igre i muzike. Proizvodi izrađeni tradicionalnim tehnikama  mogu se kupiti  na mnogobrojnim sajmovima koji se organizuju tokom godine na raznim lokacijama u i van opštine Štrpce.
    U restoranima sa nacionalnom kuhinjom često ćete biti usluženi tradicionalnim šarskim sirom, domaćom filijom ili šarskim jagnjetom. U ponudi su i domaći sokovi od maline, kupine, borovnice, a dobrodošlicu će vam poželeti posluženjem slatkog od šumskih jagodica. Jela se pripremaju po isprobanim tradicionalnim receptima. 
    Tri kulturno umetnička društva kroz svoj rad čuvaju pesme i igre iz Sirinićke župe i ostalih krajeva. Čestim koncertima u Štrpcu ali i učestvovanjem na manifestacijama u regionu predstavljaju opštinu Štrpce na najbolji način i samim tim su i pravi ambasadori naše opštine. 
    Manifestacija Bele poklade( Vučari, ženidba Kraljevića Marka i paljenje kumbare) koja se obeležava dan pre početka Uskršnjeg posta kao i Lazarice koja se obeležavaju nedelju dana pre Uskrsa na Lazarevu subotu, praznik kojim se proslavlja Hristovo vaskrsenje Lazara posle četvorodnevne smrti, su na listi  kulturne baštine. Obe manifestacije okupljaju veliki broj mladih ljudi i dece i na taj način rade na očuvanju tradicije unazad decenijama i vekovima.
    `,
  },
  al: {
    title: "TRADITA",
    text: `Ruajtja e traditës reflektohet në kultivimin e zejeve të vjetra, kuzhinës tradicionale, zakoneve popullore, fjalës së shkruar, këngës, vallëzimit dhe muzikës.  Produktet e bëra me teknika tradicionale mund të blihen në panaire të shumta që organizohen gjatë gjithë vitit në lokacione të ndryshme brenda dhe jashtë komunës së Shtërpcës.
    Në restorantet me kuzhinë kombëtare, shpesh do t'ju shërbehet djathi tradicional Sharjah, fileto shtëpie ose mish qengji Sharjah.  Lëngjet e mjedrës, manaferrës dhe boronicës së bërë në shtëpi janë gjithashtu në ofertë, të cilat do t'ju mirëpresin me kokrra të ëmbla të egra.  Pjatat përgatiten sipas recetave tradicionale të provuara.
    Nëpërmjet punës së tyre, tri shoqëri kulturore dhe artistike ruajnë këngë dhe valle nga famullia e Siriniqit dhe rajone të tjera.  Me koncerte të shpeshta në Shtërpcë, por edhe me pjesëmarrje në ngjarje të rajonit, ata e përfaqësojnë komunën e Shtërpcës në mënyrën më të mirë, dhe kështu janë ambasadorë të vërtetë të komunës sonë.
    Ngjarja e Karnavalit të Bardhë (Vuchari, dasma e Marko Kraljeviqit dhe djegia e kumbarës), e cila festohet një ditë para fillimit të agjërimit të Pashkëve, si dhe Lazarice, e cila kremtohet një javë para Pashkëve në Lazarat.  E shtuna, një festë që feston ringjalljen e Krishtit të Llazarit pas një vdekjeje katërditore, janë në listën e trashëgimisë kulturore.  Të dyja ngjarjet bashkojnë një numër të madh të rinjsh dhe fëmijë dhe kështu punojnë për të ruajtur traditat që datojnë dekada dhe shekuj.
  `,
  },
};

export const tourismData = {
  en: {
    title: "COUNTRYSIDE TOURISM",
    text: `Here, tradition is not only mentioned but also alive, and the proof of that is the rural households that have decided to open their gates to tourists.  The hosts are hard-working and hospitable, the facilities are nicely decorated, a variety of homemade and healthy food is served, the famous Sharjah cheese and cold spring water or homemade brand.`,
  },
  sr: {
    title: "SEOSKI TURIZAM",
    text: "Ovde se tradicija ne samo spominje nego i živi, a dokaz za to su seoska domaćinstva koja su  odlučila  da svoje kapije otvore turistima. Domaćini su vredni i gostoljubivi, objekti lepo uređeni, služi se raznovrsna domaća i zdrava hrana, čuveni šarski sir i hladna izvorska voda ili domaća rakija.",
  },
  al: {
    title: "TURIZMI NE FSHAT",
    text: `Këtu tradita jo vetëm përmendet por edhe e gjallë dhe dëshmi për këtë janë familjet fshatare që kanë vendosur të hapin portat e tyre për turistët.  Mikpritësit janë punëtor dhe mikpritës, ambientet janë të dekoruara bukur, ofrohet një shumëllojshmëri ushqimesh të bëra vetë dhe të shëndetshme, djathi i famshëm Sharjah dhe uji i ftohtë i burimit ose raki e shtepise.`,
  },
};

export const undpData = {
  en: {
    text: `The website is the result of the Primo la tolleranza project within the Regional Program for Local Democracy in the Western Balkans (ReLOaD2), financed by the European Union (EU) and implemented by the United Nations Development Program (UNDP) in partnership with the municipality of Strpce.`,
  },
  sr: {
    text: `Web sajt je rezultat projekta organizacije Primo la tolleranza u okviru Regionalnog programa lokalne demokratije na Zapadnom Balkanu ( ReLOaD2) a finansira Evropska Unija (EU) a sprovodi Program Ujedinjenih Nacija za razvoj (UNDP) u paretnerstvu sa opstinom Strpce.`,
  },
  al: {
    text: "Faqja e internetit është rezultat i projektit Primo la tolleranza në kuadër të Programit Rajonal për Demokracinë Lokale në Ballkanin Perëndimor (ReLOaD2), financuar nga Bashkimi Evropian (BE) dhe zbatuar nga Programi i Kombeve të Bashkuara për Zhvillim (UNDP) në partneritet me komunën. të Shtërpcës.",
  },
};
