import React from "react";
import Contact from "../../../components/user/Contact/Contact";
import UserLayout from "../Layout/Layout";
import { useLanguage } from "../../../contexts/LanguageContext";
import { contactData } from "../../../data";

const ContactPage = () => {
  const { lang } = useLanguage();
  const data = contactData[lang];

  return (
    <UserLayout text={data.title}>
      <Contact />
    </UserLayout>
  );
};

export default ContactPage;
