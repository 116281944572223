import React from "react";
import "./events.scss";
import { Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { useEvents } from "../../contexts/EventsContext";

const NoData = () => {
  const { lang } = useLanguage();

  let text = "Trenutno nema vesti.";

  if (lang === "en") {
    text = "There is no news";
  }

  if (lang === "al") {
    text = "Asnjë lajm për momentin";
  }
  return (
    <div className="noData">
      <h3 style={{ textAlign: "center" }}> {text} </h3>
    </div>
  );
};

const Card = ({ event }) => {
  // const formatDate = moment(event.created_at).format("DD-MM-YYYY");
  const files = event?.files?.length && event?.files?.split("#");

  return (
    <div className="eventCard">
      <Link to={`/events/${event._id}`}>
        <div className="eventCardBody">
          {files && files[0] && <img src={`/events/${files[0]}`} alt="" />}
        </div>
        <div className="eventCardFooter">
          <h3>{event.title}</h3>
          <p>{event.short_text || ""}</p>
          {/* <div
											dangerouslySetInnerHTML={{
													__html: event?.draft,
											}}
											style={{ maxWidth: "100%", textAlign: " justify" }}
									></div> */}
        </div>
      </Link>
    </div>
  );
};

const Blog = () => {
  const { events } = useEvents();
  const { lang } = useLanguage();
  // const lastThree = events.slice(events.length - 3);

  let text = "VESTI";
  let buttonText = "SVE VESTI";

  if (lang === "en") {
    text = "NEWS";
    buttonText = "ALL NEWS";
  }

  if (lang === "al") {
    text = "LAJME";
    buttonText = "TË GJITHA LAJMET";
  }

  return (
    <div className="eventsWrapper">
      <div className="headline">
        <h2>{text}</h2>
      </div>
      <div>
        <div className="events">
          {events?.length > 0 ? (
            events.map((blog) => {
              return <Card key={blog._id} event={blog} />;
            })
          ) : (
            <NoData />
          )}
        </div>
        {events?.length && (
          <div className="allNews">
            <button>{buttonText}</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
