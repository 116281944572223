import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function AlertModal({ error, setError, message }) {
  const [smShow, setSmShow] = useState(false);

	const hideModal = () => {
		setError(false)
		setSmShow(false)
	}

  return (
    <>
      <Modal
        size="sm"
        show={error ? true : false}
        onHide={hideModal}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" className='text-danger'>
						Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-danger'>
					{ message }
					</Modal.Body>
      </Modal>
    </>
  );
}

export default AlertModal