import React from "react";
import "./events.scss";
import UserLayout from "../Layout/Layout";
import { useLanguage } from "../../../contexts/LanguageContext";
import { Link } from "react-router-dom";
import { useBlogs } from "../../../contexts/BlogContext";

const NoData = () => {
  const { lang } = useLanguage();

  let text = "Trenutno nema dogadjaja.";

  if (lang === "en") {
    text = "There is no events.";
  }

  if (lang === "al") {
    text = "Asnjë lajm për momentin.";
  }
  return <h3 style={{margin: '0 auto 40px'}}> {text} </h3>;
};

const Event = ({ event }) => {
  // const formatDate = moment(event.created_at).format("DD-MM-YYYY");
  const files = event?.files?.length && event?.files?.split("#");

  return (
    <div className="eventCard">
      <Link to={`/events/${event._id}`}>
        <div className="eventCardBody">
          {files && files[0] && <img src={`/blogs/${files[0]}`} alt="" />}
        </div>
        <div className="eventCardFooter">
          <h3>{event.title}</h3>
          <p>{event.short_text || ""}</p>
          {/* <div
											dangerouslySetInnerHTML={{
													__html: event?.draft,
											}}
											style={{ maxWidth: "100%", textAlign: " justify" }}
									></div> */}
        </div>
      </Link>
    </div>
  );
};

export const EventsComponent = () => {
  const { blogs } = useBlogs();
  return (
    <div className="news-page">
      <div className="events">
        {blogs?.length > 0 ? (
          blogs.map((blog) => {
            return <Event key={blog._id} event={blog} />;
          })
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

const Events = () => {
  const { lang } = useLanguage();
  let text = "KALENDAR DOGADJAJA";
  if (lang === "en") {
    text = "CALENDAR OF EVENTS";
  }
  if (lang === "al") {
    text = "KALENDARI I NGJARJEVE";
  }

  return (
    <UserLayout text={text}>
      <EventsComponent />
    </UserLayout>
  );
};

export default Events;
