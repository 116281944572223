import React from "react";
import "./contact.scss";
import { HiLocationMarker } from "react-icons/hi";
import { useLanguage } from "../../../contexts/LanguageContext";
import { contactData } from "../../../data";
import { AiOutlinePhone } from "react-icons/ai";
import { BiStreetView } from "react-icons/bi";

const Contact = () => {
  const { lang } = useLanguage();

  const data = contactData[lang];

  return (
    <div className="contact">
      <div className="container">
        {/* <h2>Contact Us</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus
          repellendus quas nesciunt harum ipsam culpa!
        </p> */}
        <div className="row">
          <div className="info">
            <h3>{data.text} </h3>
            {/* <div>
              <div className="icon">
                <AiOutlinePhone color={"#fff"} size={20} />
              </div>
              <div className="text">
                <p>0800 21 000</p>
              </div>
            </div> */}
            <div>
              <div className="icon">
                <BiStreetView color={"#fff"} size={20} />
              </div>
              <div className="text">
                <p>Glavna ulica, 73000 Štrpce</p>
              </div>
            </div>
            <div>
              <div className="icon">
                <HiLocationMarker color={"#fff"} size={20} />
              </div>
              <div className="text">
                <p>
                  <a href="mailto:info@turistickaorganizacijastrpce.com">
                    info@turistickaorganizacijastrpce.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/*<div className="form">
            <form onSubmit={send}>
              <h3>Send Message</h3>
              <div className="form-group">
                <input type="text" placeholder="Full Name" defaultValue={mail.name} onChange={e => setMail({name: e.target.value })} />
              </div>
              <div className="form-group">
                <input type="email" placeholder="Email" defaultValue={mail.email} onChange={e => setMail({email: e.target.value })}  />
              </div>
              <div className="form-group">
                <textarea
                  placeholder="Type your message..."
                  id=""
                  cols="10"
                  rows="10"
                  defaultValue={mail.message}
                  onChange={e => setMail({message: e.target.value })} 
                ></textarea>
              </div>
              <button>Send</button>
            </form>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Contact;
