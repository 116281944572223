import { convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import EditorConvertToHTML from "../../../components/admin/Editor/Editor";
import { useEvents } from "../../../contexts/EventsContext";

const content = {
  entityMap: {},
  blocks: [
    {
      key: "637gr",
      text: "Initialized from content state.",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};

const postResponseHandler = async (formData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/blog`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.log("error", error);
  }
};

function AddBlog() {
  const [show, setShow] = useState(false);
  const [contentState, setContentState] = useState(convertFromRaw(content));
  const [title, setTitle] = useState("");
  const [shortText, setShortText] = useState("");
  const [files, setFiles] = useState([]);
  const [language, setLanguage] = useState("sr");
  const [error, setError] = useState(false);
  const { setBlogs } = useEvents();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData();

    formData.append("title", title);
    formData.append("language", language);
    formData.append("draft", draftToHtml(contentState));
    formData.append("short_text", shortText);

    for (let file of files) {
      formData.append("files", file);
    }
    const data = await postResponseHandler(formData);

    if (data?.error) {
      setError(data.error);
    }

    if (data?.status) {
      setBlogs(data.blogs);
      handleClose();
    }
  };

  const onContentStateChange = (contentState) => {
    setContentState(contentState);
  };

  return (
    <>
      <Button onClick={handleShow} className="add">
        Kreiraj novi dogadjaj
      </Button>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header
          className="custom-modal-header"
          closeButton
          closeVariant="white"
        >
          <Modal.Title>Kreiraj novi dogadjaj</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Dodaj sliku</Form.Label> <br />
              <Form.Control
                type="file"
                onChange={(e) => setFiles(e.target.files)}
                multiple
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Naslov</Form.Label> <br />
              <Form.Control
                type="text"
                placeholder="Tekst..."
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Kratki opis</Form.Label> <br />
              <Form.Control
                type="text"
                placeholder="Kratki opis"
                onChange={(e) => setShortText(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              style={{ height: "300px" }}
            >
              <Form.Label>Opis</Form.Label>
              <EditorConvertToHTML
                onContentStateChange={onContentStateChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Jezik</Form.Label>
              <Form.Select
                rows={3}
                onChange={(e) => setLanguage(e.target.value)}
                required
              >
                <option value="sr">Srpski</option>
                <option value="en">English</option>
                <option value="al">Albanian</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
          {error && <div className="alert alert-danger"></div>}
          <Button className="bg-danger" onClick={handleClose}>
            Kreiraj
          </Button>
          <Button className="" type="submit" onClick={handleSubmit}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddBlog;
