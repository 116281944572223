import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./pages/user/Home";
import Fallback from "./pages/user/Fallback";
import ProtectedRoute from "./helpers/protectedRoute";
import Login from "./pages/admin/Login/Login";
import Admin from "./pages/admin/Admin/Admin";
import BlogDetails from "./pages/user/BlogDetails/BlogDetails";
import AboutUs from "./pages/user/AboutUs/AboutUs";
import ContactPage from "./pages/user/Contact/Contact";
// import News from "./pages/user/News/News";
import City from "./pages/user/City/City";
import History from "./pages/user/History/History";
import Tradition from "./pages/user/Tradition/Tradition";
import Tourism from "./pages/user/Tourism/Tourism";
import Events from "./pages/user/Events/Events";
import News from "./pages/admin/News/News";
import Offers from "./pages/user/Offers/Offers";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route key="firstRoute" path="/" element={<Home />} />,
      <Route path="/about" element={<AboutUs />} />,
      <Route path="/city" element={<City />} />,
      <Route path="/history" element={<History />} />,
      <Route path="/tradition" element={<Tradition />} />,
      <Route path="/tourism" element={<Tourism />} />,
      <Route path="/contact" element={<ContactPage />} />,
      <Route path="/events" element={<Events />} />,
      <Route path="/events/:id" element={<BlogDetails />} />,
      <Route path="/offers" element={<Offers />} />,
      <Route key="loginRoute" path="/login" element={<Login />} />,
      <Route
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
        path="/admin"
      />,
      <Route
        element={
          <ProtectedRoute>
            <News />
          </ProtectedRoute>
        }
        path="/admin/blog"
      />,
      <Route key="cacheAllRoute" element={<Fallback />} path="*" />,
    ])
  );

  return <RouterProvider router={router} />;
}

export default App;
