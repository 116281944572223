import { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { navData } from "../../data";
import "./navbar.scss";

const Navbar = () => {
  const { lang } = useLanguage();
  const links = navData[lang].links;
  const [isActive, setIsActive] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  return (
    <div className="navbar sticky-top">
      <div className="container">
        <Link to="/" className="logo">
          <img src="/logo.jpeg" height={70} alt="" />
          <div className="menu-btn" onClick={onClick}>
            {!isActive ? (
              <AiOutlineMenu size={30} color={"#000"} />
            ) : (
              <AiOutlineClose size={30} color={"#000"} />
            )}
          </div>
        </Link>
        <div className={`links ${isActive && "active"}`}>
          {links.length &&
            links.map((link) => (
              <NavLink to={link.to} className={`link`} key={link.id}>
                {link.title}
              </NavLink>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
