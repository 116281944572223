import React from "react";
import { missionData } from "../../data";
import "./about.scss";
import { useLanguage } from "../../contexts/LanguageContext";

const About = () => {
	const {lang} = useLanguage();
  const data = missionData[lang];

  return (
    <div className="about">
      <div className="container">
        <div className="content">
          <p>
           {data.text}
          </p>
        </div>
        <div className="img">
          <div className="img-div">
					<img src="./images/about-bg.jpg" alt="" />
					</div>
        </div>
      </div>
    </div>
  );
};

export default About;
