import React from "react";
import "./events.scss";
import { Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { useBlogs } from "../../contexts/BlogContext";

const NoData = () => {
  const { lang } = useLanguage();

  let text = "Trenutno nema ponuda.";

  if (lang === "en") {
    text = "There is no offers.";
  }

  if (lang === "al") {
    text = "Asnjë lajm për momentin.";
  }
  return (
    <div className="noData">
      <h3> {text} </h3>
    </div>
  );
};

const Event = ({ event }) => {
  // const formatDate = moment(event.created_at).format("DD-MM-YYYY");
  const files = event?.files?.length && event?.files?.split("#");

  return (
    <div className="eventCard">
      <Link to={`/events/${event._id}`}>
        <div className="eventCardBody">
          {files && files[0] && <img src={`/blogs/${files[0]}`} alt="" />}
        </div>
        <div className="eventCardFooter">
          <h3>{event.title}</h3>
          <p>{event.short_text || ""}</p>
          {/* <div
											dangerouslySetInnerHTML={{
													__html: event?.draft,
											}}
											style={{ maxWidth: "100%", textAlign: " justify" }}
									></div> */}
        </div>
      </Link>
    </div>
  );
};

export const EventsComponent = () => {
  const { blogs } = useBlogs();
  const { lang } = useLanguage();
  let text = "SVE PONUDE";
  if (lang === "en") {
    text = "ALL OFFERS";
  }
  if (lang === "al") {
    text = "OTE GJITHA OFERTA";
  }
  // const lastThree = blogs.slice(blogs.length - 3);

  return (
    <>
      <div className="events">
        {blogs?.length > 0 ? (
          blogs.map((blog) => {
            return <Event key={blog._id} event={blog} />;
          })
        ) : (
          <NoData />
        )}
      </div>
      {blogs?.length && (
        <div className="allNews">
          <button>{text}</button>
        </div>
      )}
    </>
  );
};

const TouristOffers = () => {
  const { lang } = useLanguage();
  let text = "TURISTIČKA PONUDA";
  if (lang === "en") {
    text = "TOURIST OFFER";
  }
  if (lang === "al") {
    text = "OFERTA TURISTIKE";
  }

  return (
    <div className="eventsWrapper">
      <div className="headline">
        <h2>{text}</h2>
      </div>
      <EventsComponent />
    </div>
  );
};

export default TouristOffers;
