import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import "./announcment.scss";
import { useLanguage } from "../../contexts/LanguageContext";
import { useAuth } from "../../contexts/AuthContext";
import { CiUser } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Annoucment = () => {
  const { changeLanguage } = useLanguage();
  const { user } = useAuth();
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/admin");
  };

  return (
    <div className="annoucment">
      <div className="container">
        <div className="contact-info">
          <div className="email">
            <AiOutlineMail size={18} />
            <a href="mailto:info@turistickaorganizacijastrpce.com">info@turistickaorganizacijastrpce.com</a>
          </div>
          {/* <div className="tel">
            <AiOutlinePhone size={18} />
            <span>0800 21 000</span>
          </div> */}
        </div>
        <div className="lang">
          <span onClick={() => changeLanguage("sr")}>SRPSKI</span>
          <span onClick={() => changeLanguage("en")}>ENGLISH</span>
          <span onClick={() => changeLanguage("al")}>ALBANIAN</span>
          {user && (
            <button type="button" onClick={onClick}>
              <span>
                <CiUser size={20} />
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Annoucment;
