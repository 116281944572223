import { createContext, useContext, useEffect, useState } from "react";
import { useLanguage } from "./LanguageContext";
import { fetchData } from "../helpers/api";

const BlogContext = createContext(null);

export const BlogProvider = ({ children }) => {
  const { lang } = useLanguage();
  const [blogs, setBlogs] = useState([]);

  const fetchNews = async () => {
    fetchData("/blog").then((res) => {
      setBlogs(res.blogs);
    });
  };

  useEffect(() => {
    fetchNews();
  }, [lang]);

  return (
    <BlogContext.Provider value={{ blogs, setBlogs }}>
      {children}
    </BlogContext.Provider>
  );
};

export const useBlogs = () => {
  const context = useContext(BlogContext);

  if (!context) {
    throw new Error("Wrapp <App /> component with <BlogContext.Provider>!");
  }

  return context;
};
