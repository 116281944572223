import React from "react";
import Annoucment from "../../../components/Announcment/Announcment";
import Navbar from "../../../components/Navbar/Navbar";
import Hero from "../../../components/user/Hero/Hero";
import Footer from "../../../components/Footer/Footer";
import useScrollToTop from "../../../helpers/useScrollToTop";

const UserLayout = ({ children, text }) => {
  useScrollToTop();
  return (
    <React.Fragment>
      <Annoucment />
      <Navbar />
      <Hero text={text} />
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default UserLayout;
