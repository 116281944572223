import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditorConvertToHTML = ({ onContentStateChange }) => {
  return (
    <div>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorStyle={{ background: "#fff", height: "150px" }}
        wrapperStyle={{ background: "#fff" }}
        onContentStateChange={onContentStateChange}
      />
    </div>
  );
};

export default EditorConvertToHTML;
