import React from "react";
import "./sidebar.scss";
import { RiDashboardFill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

const Sidebar = ({ closeSidebar, isSidebarOpen }) => {
  const { logout } = useAuth();

  return (
    <div className={`sidebar ${isSidebarOpen && "active"}`}>
      <div className="close-menu" onClick={closeSidebar}>
        <AiOutlineClose size={25} />
      </div>
      <div className="logo">
        <span>Admin.</span>
      </div>

      <div className="menu-bar">
        <span className="bar-title">Meni</span>
        <Link to="/admin" className="link">
          <RiDashboardFill size={20} />
          <span>Ponude</span>
        </Link>
        <Link to="/admin/blog" className="link">
          <RiDashboardFill size={20} />
          <span>Dogadjaji</span>
        </Link>
      </div>
      <div className="footer-sidebar">
        <Link to="/" className="link">
          <RiDashboardFill size={20} />
          <span>Idi na sajt</span>
        </Link>
        <button to="/logout" className="link" onClick={logout}>
          <RiDashboardFill size={20} />
          <span>Odjavi se</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
