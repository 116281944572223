import { createContext, useContext, useEffect, useState } from "react";
import { useLanguage } from "./LanguageContext";
import { fetchData } from "../helpers/api";

const EventsContext = createContext(null);

export const EventsProvider = ({ children }) => {
  const { lang } = useLanguage();
  const [events, setEvents] = useState([]);

  const fetchBlogs = async () => {
    fetchData("/events").then((res) => {
      console.log({res})
      setEvents(res.events);
    });
  };

  useEffect(() => {
    fetchBlogs()
  }, [lang]);

  return (
    <EventsContext.Provider value={{ events, setEvents }}>
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => {
  const context = useContext(EventsContext);

  if (!context) {
    throw new Error("Wrapp <App /> component with <EventsContext.Provider>!");
  }

  return context;
};
