import React, { useMemo, useState } from "react";
import { BiEdit, BiSpreadsheet, BiTrashAlt } from "react-icons/bi";
import AlertModal from "../../../components/admin/Modal/AlertModal";
import { authCalls } from "../../../helpers/api";
import Layout from "../../../components/admin/Layout/Layout";
import AddBlog from "../../../components/admin/Modal/AddBlog";
import moment from "moment";
import { useBlogs } from "../../../contexts/BlogContext";

const News = () => {
  const { blogs, setBlogs } = useBlogs();
  const [lang, setLang] = useState(null);
  const [error, setError] = useState(null);

  console.log({ blogs });
  const sortedBlogs = useMemo(() => {
    if (lang === null || !lang.length) return blogs;
    return blogs.filter((blog) => blog.language === lang);
  }, [lang, blogs]);

  const handleSelect = (e) => {
    console.log(e.target.checked);
  };

  const handleDelete = async (id) => {
    const response = await authCalls(`/blog/${id}`, "DELETE");

    if (response.status) {
      setBlogs(blogs.filter((blog) => String(blog._id) !== response.id));
    } else {
      setError(true);
    }
  };

  return (
    <Layout>
      <div className="section dashboard">
        {error && (
          <AlertModal
            error={error}
            setError={setError}
            message={"Something goes wrong"}
          />
        )}
        <div className="table-custom">
          <h4 className="table-title">Svi dogadjaji</h4>
          <div className="table-grid">
            <div className="table-actions">
              <AddBlog />
              <span className="delete">
                <BiTrashAlt size={20} />
              </span>
              {/* <select
                className="sortby"
                onChange={(e) => setLang(e.target.value)}
              >
                <option value=""></option>
                <option value="al">Albanian</option>
                <option value="sr">Serbian</option>
                <option value="en">English</option>
              </select> */}
            </div>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Naslov</th>
                  <th>Slika</th>
                  <th>Jezik</th>
                  <th>Datum kreiranja</th>
                  <th colSpan={3}>Izbrisi</th>
                </tr>
              </thead>
              <tbody>
                {sortedBlogs?.length &&
                  blogs.map((blog) => (
                    <tr key={blog._id}>
                      <td>
                        <input type="checkbox" onClick={handleSelect} />
                      </td>
                      <td>{blog._id}</td>
                      <td>
                        {blog.title.length > 50
                          ? blog.title.slice(50) + "..."
                          : blog.title}
                      </td>
                      <td>
                        {" "}
                        <img
                          src={`./blogs/${blog.files.split("#")[0]}`}
                          width={50}
                          alt=""
                        />{" "}
                      </td>
                      <td>{blog.language}</td>
                      <td>{moment(blog.createdAt).format("DD-MM-YYYY")}</td>
                      <td>
                        <BiSpreadsheet size={25} />
                      </td>
                      <td>
                        <BiEdit size={25} />
                      </td>
                      <td
                        onClick={() => handleDelete(blog._id)}
                        style={{ cursor: "pointer" }}
                      >
                        <BiTrashAlt color={"red"} size={25} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {/* <div className="pagination">pagination</div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default News;
