import React from "react";
import "./offers.scss";
import UserLayout from "../Layout/Layout";
import { useEvents } from "../../../contexts/EventsContext";
import { useLanguage } from "../../../contexts/LanguageContext";
import { Link } from "react-router-dom";

const NoData = () => {
  const { lang } = useLanguage();

  let text = "Trenutno nema ponuda.";

  if (lang === "en") {
    text = "There is no offers.";
  }

  if (lang === "al") {
    text = "Asnjë lajm për momentin.";
  }
  return <h3 style={{ margin: "0 auto 40px" }}> {text} </h3>;
};

const Event = ({ event }) => {
  const files = event?.files?.length && event?.files?.split("#");

  return (
    <div className="eventCard">
      <Link to={`/events/${event._id}`}>
        <div className="eventCardBody">
          {files && files[0] && <img src={`/events/${files[0]}`} alt="" />}
        </div>
        <div className="eventCardFooter">
          <h3>{event.title}</h3>
          <p>{event.short_text || ""}</p>
          {/* <div
											dangerouslySetInnerHTML={{
													__html: event?.draft,
											}}
											style={{ maxWidth: "100%", textAlign: " justify" }}
									></div> */}
        </div>
      </Link>
    </div>
  );
};

export const EventsComponent = () => {
  const { events } = useEvents();
  return (
    <div className="news-page">
      <div className="events">
        {events?.length > 0 ? (
          events.map((blog) => {
            return <Event key={blog._id} event={blog} />;
          })
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

const Offers = () => {
  const { lang } = useLanguage();

  let text = "TURISTIČKA PONUDA";
  if (lang === "en") {
    text = "TOURIST OFFER";
  }
  if (lang === "al") {
    text = "OFERTA TURISTIK";
  }

  return (
    <UserLayout text={text}>
      <EventsComponent />
    </UserLayout>
  );
};

export default Offers;
