import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { getTokenApi } from "./api";

const ProtectedRoute = ({ children }) => {
  const { user, logout } = useAuth();

  useEffect(() => {
    const getToken = async (token) => {
      const data = await getTokenApi(token);
      if (data.error) {
        logout();
      }
    };
    if (localStorage.getItem("access_token")) {
      getToken(localStorage.getItem("access_token"));
    }
  }, [user]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default ProtectedRoute;
